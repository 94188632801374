import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Layout from '../components/Layout/Layout';
import axiosAuthProtected from '../utils/axiosAuthProtected';
import { CSSTransition } from 'react-transition-group';

const Registration = () => {
    const [formData, setFormData] = useState({
        brandingName: '',
        registeredName: '',
        location: '',
        industry: '',
        email: ''
    });
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axiosAuthProtected.post('/startup/update-company/', {
                brand_name: formData.brandingName,
                city: formData.location,
                industry: formData.industry,
                email: formData.email
            });
            if (response.data.success) {
                console.log('Company data submitted:', response.data);
                navigate('/funding-goal');
            } else {
                setError('Failed to update company information. Please try again.');
            }
        } catch (error) {
            console.error('Error updating company information:', error);
            setError('Failed to update company information. Please try again.');
        }
    };

    return (
        <Layout currentStep={0}>
            <CSSTransition in={true} appear={true} timeout={750} classNames="fade">
                <form onSubmit={handleSubmit} className="bg-yellow-500 p-6 rounded-2xl shadow-md w-full max-w-md mx-auto mr-2 ml-2">
                    <h1 className="text-2xl font-bold mb-4">Let's get to know you better!</h1>
                    <div className="mb-4">
                        <label htmlFor="brandingName" className="block text-black font-bold">Brand Name</label>
                        <input
                            type="text"
                            id="brandingName"
                            name="brandingName"
                            value={formData.brandingName}
                            onChange={handleChange}
                            className="mt-1 block w-full px-4 py-2 border border-yellow-500 rounded-md bg-yellow-200 text-black"
                            required
                        />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="location" className="block text-black font-bold">HQ Location</label>
                        <input
                            type="text"
                            id="location"
                            name="location"
                            value={formData.location}
                            onChange={handleChange}
                            className="mt-1 block w-full px-4 py-2 border border-yellow-500 rounded-md bg-yellow-200 text-black"
                            required
                        />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="industry" className="block text-black font-bold">Industry</label>
                        <input
                            type="text"
                            id="industry"
                            name="industry"
                            value={formData.industry}
                            onChange={handleChange}
                            className="mt-1 block w-full px-4 py-2 border border-yellow-500 rounded-md bg-yellow-200 text-black"
                            required
                        />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="email" className="block text-black font-bold">Email</label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            className="mt-1 block w-full px-4 py-2 border border-yellow-500 rounded-md bg-yellow-200 text-black"
                            required
                        />
                    </div>
                    {error && <p className="text-red-500 mb-4">{error}</p>}
                    <button type="submit" className="bg-black hover:bg-gray-900 text-white px-4 py-2 rounded-lg w-full font-bold">Submit</button>
                </form>
            </CSSTransition>
        </Layout>
    );
};

export default Registration;