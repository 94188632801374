import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LandingPage from './pages/LandingPage';
import OTPVerification from './pages/OTPVerification';
import Registration from './pages/Registration';
import FundingGoal from './pages/FundingGoal';
import Payment from './pages/Payment';
import CoFounderDetails from './pages/FounderDetails';
import PitchDeckUpload from './pages/PitchDeckUpload';
import DocumentsUpload from './pages/DocumentsUpload';
import FAQ from './pages/FAQ';
import AllSet from './pages/AllSet';
import Dashboard from './pages/Dashboard';
import Header from './components/Layout/Header';
import Footer from './components/Layout/Footer';
import ResponsiveExample from "./pages/ResponsiveExample";
import PhoneNumberEntry from "./pages/PhoneNumberEntry";
import PendingDocs from "./pages/PendingDocs";

const App = () => {
    return (
        <Router>
            <div className="flex flex-col min-h-screen">
                <Header />
                <main className="flex-grow pt-16 pb-16 overflow-auto w-full">
                    <Routes>
                        <Route path="/" element={<LandingPage />} />
                        <Route path="/verify-phone" element={<PhoneNumberEntry />} />
                        <Route path="/verify-otp" element={<OTPVerification />} />
                        <Route path="/registration" element={<Registration />} />
                        <Route path="/funding-goal" element={<FundingGoal />} />
                        <Route path="/payment" element={<Payment />} />
                        {/*<Route path="/founder" element={<CoFounderDetails />} />*/}
                        {/*<Route path="/pitch-deck" element={<PitchDeckUpload />} />*/}
                        {/*<Route path="/documents" element={<DocumentsUpload />} />*/}
                        {/*<Route path="/faq" element={<FAQ />} />*/}
                        {/*<Route path="/all-set" element={<AllSet />} />*/}
                        {/*<Route path="/dashboard" element={<Dashboard />} />*/}
                        <Route path="/all-set" element={<PendingDocs />} />
                    </Routes>
                </main>
                <Footer />
            </div>
        </Router>
    );
};

export default App;