import React, { useState } from 'react';
import Layout from '../components/Layout/Layout';
import { useNavigate } from 'react-router-dom';

const FounderDetails = () => {
    const [founders, setFounders] = useState([]);
    const [currentFounder, setCurrentFounder] = useState({
        name: '',
        linkedin: '',
        twitter: '',
        bio: '',
        image: null
    });
    const [isEditing, setIsEditing] = useState(false);
    const navigate = useNavigate();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setCurrentFounder({ ...currentFounder, [name]: value });
    };

    const handleImageChange = (e) => {
        setCurrentFounder({ ...currentFounder, image: e.target.files[0] });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setFounders([...founders, currentFounder]);
        setCurrentFounder({
            name: '',
            linkedin: '',
            twitter: '',
            bio: '',
            image: null
        });
        setIsEditing(false);
    };

    const handleEdit = (index) => {
        setCurrentFounder(founders[index]);
        setIsEditing(true);
    };

    const handleSaveAndContinue = () => {
        // Save the founders data and navigate to the next step
        console.log('Founders Details submitted:', founders);
        navigate('/upload-pitch-deck');
    };

    return (
        <Layout currentStep={5}>
            <div className="flex flex-col items-center justify-center w-full px-4 rounded-lg">
                <div className="bg-yellow-500 p-6 rounded-lg shadow-md w-full max-w-3xl mx-auto text-center">
                    <h2 className="text-black font-bold mb-4">Founder Details</h2>
                    {!isEditing && (
                        <button
                            className="bg-black text-white px-4 py-2 rounded font-bold mb-4"
                            onClick={() => setIsEditing(true)}
                        >
                            Add New Founder
                        </button>
                    )}
                    {isEditing && (
                        <form onSubmit={handleSubmit} className="bg-yellow-300 p-4 rounded-lg shadow-md w-full max-w-md mx-auto mb-4">
                            <div className="mb-4">
                                <label htmlFor="name" className="block text-black font-bold">Name</label>
                                <input
                                    type="text"
                                    id="name"
                                    name="name"
                                    value={currentFounder.name}
                                    onChange={handleChange}
                                    className="mt-1 block w-full px-4 py-2 border border-yellow-500 rounded-md bg-yellow-200 text-black"
                                    required
                                />
                            </div>
                            <div className="mb-4">
                                <label htmlFor="linkedin" className="block text-black font-bold">LinkedIn</label>
                                <input
                                    type="url"
                                    id="linkedin"
                                    name="linkedin"
                                    value={currentFounder.linkedin}
                                    onChange={handleChange}
                                    className="mt-1 block w-full px-4 py-2 border border-yellow-500 rounded-md bg-yellow-200 text-black"
                                />
                            </div>
                            <div className="mb-4">
                                <label htmlFor="twitter" className="block text-black font-bold">Twitter</label>
                                <input
                                    type="url"
                                    id="twitter"
                                    name="twitter"
                                    value={currentFounder.twitter}
                                    onChange={handleChange}
                                    className="mt-1 block w-full px-4 py-2 border border-yellow-500 rounded-md bg-yellow-200 text-black"
                                />
                            </div>
                            <div className="mb-4">
                                <label htmlFor="bio" className="block text-black font-bold">Bio</label>
                                <textarea
                                    id="bio"
                                    name="bio"
                                    value={currentFounder.bio}
                                    onChange={handleChange}
                                    className="mt-1 block w-full px-4 py-2 border border-yellow-500 rounded-md bg-yellow-200 text-black"
                                    rows="4"
                                    required
                                ></textarea>
                            </div>
                            <div className="mb-4">
                                <label htmlFor="image" className="block text-black font-bold">Image</label>
                                <input
                                    type="file"
                                    id="image"
                                    name="image"
                                    onChange={handleImageChange}
                                    className="mt-1 block w-full px-4 py-2 border border-yellow-500 rounded-md bg-yellow-200 text-black"
                                    required
                                />
                            </div>
                            <button type="submit" className="bg-black text-white px-4 py-2 rounded font-bold">
                                Save Founder
                            </button>
                        </form>
                    )}
                    <div className="mt-4 grid grid-cols-1 md:grid-cols-2 gap-4">
                        {founders.map((founder, index) => (
                            <div key={index} className="bg-yellow-300 p-4 rounded shadow-md text-left">
                                <div className="flex items-center justify-between mb-2">
                                    <h3 className="text-black font-bold">{founder.name}</h3>
                                    <button
                                        className="text-sm text-blue-500"
                                        onClick={() => handleEdit(index)}
                                    >
                                        Edit
                                    </button>
                                </div>
                                <p className="text-black mb-2">{founder.bio}</p>
                                {founder.image && (
                                    <img
                                        src={URL.createObjectURL(founder.image)}
                                        alt="Founder"
                                        className="mt-2 rounded-full w-16 h-16 object-cover"
                                    />
                                )}
                            </div>
                        ))}
                    </div>
                    {founders.length > 0 && (
                        <button
                            onClick={handleSaveAndContinue}
                            className="mt-6 bg-black text-white px-4 py-2 rounded font-bold"
                        >
                            Save and Continue
                        </button>
                    )}
                </div>
            </div>
        </Layout>
    );
};

export default FounderDetails;