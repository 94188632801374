import React, { useState, useEffect } from 'react';
import Header from '../components/Layout/Header';
import Footer from '../components/Layout/Footer';

const Dashboard = () => {
    const [status, setStatus] = useState('Pending');
    const [details, setDetails] = useState({
        brandingName: '',
        registeredName: '',
        location: '',
        industry: '',
        email: '',
        phoneNumber: '',
        fundingGoal: '',
        timeline: '',
        coFounders: [],
        pitchDeck: '',
        documents: {},
        faqs: [],
    });

    useEffect(() => {
        // Fetch user details from API and update state
        // Example:
        // setStatus('Approved');
        // setDetails({ brandingName: 'My Startup', registeredName: 'My Startup Pvt Ltd', ... });
    }, []);

    return (
        <div>
            <Header />
            <main className="flex flex-col items-center justify-center min-h-screen bg-gray-100 p-4">
                <h1 className="text-2xl font-bold mb-4">Dashboard</h1>
                <p className="text-lg mb-4">Status: {status}</p>
                <div className="bg-white p-6 rounded shadow-md w-full max-w-2xl">
                    <h2 className="text-xl font-bold mb-4">Startup Details</h2>
                    <p><strong>Branding Name:</strong> {details.brandingName}</p>
                    <p><strong>Registered Name:</strong> {details.registeredName}</p>
                    <p><strong>Location:</strong> {details.location}</p>
                    <p><strong>Industry:</strong> {details.industry}</p>
                    <p><strong>Email:</strong> {details.email}</p>
                    <p><strong>Phone Number:</strong> {details.phoneNumber}</p>
                    <p><strong>Funding Goal:</strong> {details.fundingGoal}</p>
                    <p><strong>Timeline:</strong> {details.timeline} days</p>

                    <h2 className="text-xl font-bold mt-6 mb-4">Co-Founders</h2>
                    {details.coFounders.map((coFounder, index) => (
                        <div key={index} className="mb-4">
                            <p><strong>Name:</strong> {coFounder.name}</p>
                            <p><strong>LinkedIn:</strong> {coFounder.linkedin}</p>
                            <p><strong>Twitter:</strong> {coFounder.twitter}</p>
                            <p><strong>Bio:</strong> {coFounder.bio}</p>
                            {coFounder.image && <img src={coFounder.image} alt={`Co-Founder ${index}`} className="mt-2 w-20 h-20 object-cover rounded-full" />}
                        </div>
                    ))}

                    <h2 className="text-xl font-bold mt-6 mb-4">Pitch Deck</h2>
                    {details.pitchDeck && <a href={details.pitchDeck} target="_blank" rel="noopener noreferrer" className="text-blue-500">View Pitch Deck</a>}

                    <h2 className="text-xl font-bold mt-6 mb-4">Documents</h2>
                    {Object.keys(details.documents).map((doc, index) => (
                        <div key={index} className="mb-4">
                            <p><strong>{doc}:</strong> {details.documents[doc] && <a href={details.documents[doc]} target="_blank" rel="noopener noreferrer" className="text-blue-500">View Document</a>}</p>
                        </div>
                    ))}

                    <h2 className="text-xl font-bold mt-6 mb-4">FAQs</h2>
                    {details.faqs.map((faq, index) => (
                        <div key={index} className="mb-4">
                            <p><strong>Q:</strong> {faq.question}</p>
                            <p><strong>A:</strong> {faq.answer}</p>
                        </div>
                    ))}
                </div>
            </main>
            <Footer />
        </div>
    );
};

export default Dashboard;