import React from 'react';
import Layout from '../components/Layout/Layout';
import { Link } from 'react-router-dom';

const PendingDocs = () => {
    return (
        <Layout currentStep={4}>
            <div className="flex flex-col items-center justify-center w-full px-4 rounded-2xl">
                <div className="bg-yellow-500 p-6 rounded-2xl shadow-md w-full max-w-md mx-auto text-center">
                    <h2 className="text-black font-bold mb-4">You are all set! Our team will reach out to you. </h2>
                    <p className="text-black mb-4 text-left">Meanwhile, please prepare the following documents.</p>
                    <ul className="text-black text-left mb-4 list-disc list-inside">
                        <li>Elevator Pitch Video (2 minutes)</li>
                        <li>Certificate of Incorporation</li>
                        <li>Memorandum of Association</li>
                        <li>Articles of Association</li>
                        <li>Company PAN</li>
                        <li>Company GST (if applicable)</li>
                        <li>Startup India Certificate</li>
                    </ul>

                    <a
                        href="/path/to/sop-document.pdf"
                        download
                        className="bg-black text-white px-4 py-2 rounded font-bold mb-4 inline-block"
                    >
                        Download Help Docs
                    </a>
                </div>
            </div>
        </Layout>
    );
};

export default PendingDocs;