import React from 'react';

const ResponsiveExample = () => {
    const isDesktop = window.innerWidth >= 1024;

    return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-primary-light text-black">
            <header className="bg-primary p-4 w-full text-center">
                <h1 className="text-2xl font-bold">Responsive Example</h1>
            </header>
            <main className="flex-grow flex items-center justify-center">
                <div className="p-4 bg-primary-dark rounded">
                    {isDesktop ? (
                        <p className="text-xl">You are on a desktop device</p>
                    ) : (
                        <p className="text-xl">You are on a mobile device</p>
                    )}
                </div>
            </main>
            <footer className="bg-primary p-4 w-full text-center">
                <p className="text-black">&copy; 2024 BloomSight. All rights reserved.</p>
            </footer>
        </div>
    );
};

export default ResponsiveExample;