import React, { useState, useEffect } from 'react';
import { isDesktop } from '../../utils/responsive';

const steps = [
    'Register Startup',
    'Set Funding Target',
    'Complete Onboarding',
];

const ProgressStepper = ({ currentStep }) => {
    const [isDesktopDevice, setIsDesktopDevice] = useState(isDesktop());

    useEffect(() => {
        const handleResize = () => setIsDesktopDevice(isDesktop());
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    if (isDesktopDevice) {
        return (
            <div className="relative w-full flex flex-col p-4">
                {steps.map((step, index) => (
                    <div key={index} className="flex items-center mb-8 relative">
                        <div className={`w-8 h-8 flex items-center justify-center rounded-full z-10 ${index <= currentStep ? 'bg-yellow-500 text-black font-bold' : 'bg-gray-300 text-gray-500 font-bold'}`}>
                            {index + 1}
                        </div>
                        <span className={`ml-4 ${index <= currentStep ? 'text-black font-bold' : 'text-gray-500'}`}>{step}</span>
                        {index < steps.length - 1 && (
                            <div className="absolute left-4 top-8 h-8 border-l-2 border-gray-300"></div>
                        )}
                    </div>
                ))}
            </div>
        );
    } else {
        return (
            <div className="relative w-full flex flex-row justify-center p-4 pb-0 mb-0">
                {steps.map((step, index) => (
                    <div key={index} className="flex items-center mb-8 relative">
                        <div className={`w-8 h-8 flex items-center justify-center rounded-full z-10 mx-2 ${index <= currentStep ? 'bg-black text-white font-bold' : 'bg-yellow-500 text-black outline font-bold'}`}>
                            {index + 1}
                        </div>
                    </div>
                ))}
            </div>
        );
    }
};

export default ProgressStepper;