import React from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../components/Layout/Header';
import Footer from '../components/Layout/Footer';

const LandingPage = () => {
    const navigate = useNavigate();

    const handleGetStarted = () => {
        navigate('/verify-phone');
    };

    return (
        <div className="flex flex-col items-center justify-center p-4 bg-primary-light text-black">
            <Header />
            <main className="flex-grow flex flex-col items-center justify-center">
                <h1 className="text-4xl font-bold mb-4 text-center">Welcome to BloomSight Ventures</h1>
                <p className="text-lg text-center mb-8">Funding Evolved: Connecting startups with everyday investors. Boosting your startup growth with management and tech consultancy.</p>
                <button onClick={handleGetStarted} className="bg-black text-white font-bold px-4 py-2 rounded-2xl text-2xl">Raise Money For My Startup</button>
            </main>
            <Footer />
        </div>
    );
};

export default LandingPage;