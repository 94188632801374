import React from 'react';
import Header from '../components/Layout/Header';
import Footer from '../components/Layout/Footer';
import ProgressStepper from '../components/Layout/ProgressStepper';

const AllSet = () => {
    return (
        <div>
            <Header />
            <ProgressStepper steps={Array.from({ length: 10 }, (_, i) => i + 1)} currentStep={10} />
            <main className="flex flex-col items-center justify-center min-h-screen bg-gray-100 p-4">
                <h1 className="text-2xl font-bold mb-4">All Set!</h1>
                <p className="text-lg text-center mb-8">Thank you for submitting your information. We will review your application and get back to you shortly.</p>
                <a href="/dashboard" className="bg-yellow-500 text-white px-4 py-2 rounded">Go to Dashboard</a>
            </main>
            <Footer />
        </div>
    );
};

export default AllSet;