import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Layout from '../components/Layout/Layout';
import { CSSTransition } from 'react-transition-group';
import axiosOpen from "../utils/axioOpen";

const OTPVerification = () => {
    const [otp, setOtp] = useState(new Array(6).fill(''));
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const location = useLocation();
    const [userId, setUserId] = useState('');
    const inputRefs = useRef([]);

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const userId = queryParams.get('user_id');
        if (userId) {
            setUserId(userId);
        } else {
            setError('User ID is missing. Please try again.');
        }
    }, [location.search]);

    const handleChange = (element, index) => {
        if (isNaN(element.value)) return;

        setOtp([...otp.map((d, idx) => (idx === index ? element.value : d))]);

        if (element.nextSibling) {
            element.nextSibling.focus();
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const otpValue = otp.join('');
        try {
            const response = await axiosOpen.post('/startup/verify/', { user_id: userId, otp: otpValue });
            const { member, registration_step, tokens } = response.data;
            console.log('Verification successful:', response.data);

            // Store tokens in localStorage
            localStorage.setItem('token', tokens.access);
            localStorage.setItem('refreshToken', tokens.refresh);

            // Navigate based on registration step
            switch (registration_step) {
                case '1':
                    navigate('/registration');
                    break;
                case '2':
                    navigate('/funding-goal');
                    break;
                case '3':
                    navigate('/payment');
                    break;
                default:
                    navigate('/');
                    break;
            }
        } catch (error) {
            console.error('Error verifying OTP:', error);
            setError('Failed to verify OTP. Please try again.');
        }
    };

    return (
        <Layout>
            <CSSTransition in={true} appear={true} timeout={750} classNames="fade">
                <div className="form-container center-content w-full">
                    <form onSubmit={handleSubmit} className="bg-yellow-500 p-6 rounded-2xl shadow-md w-full max-w-md mr-2 ml-2">
                        <div className="mb-4">
                            <label className="block text-black font-bold mb-2">Enter OTP</label>
                            <div className="flex justify-center space-x-2">
                                {otp.map((data, index) => (
                                    <input
                                        key={index}
                                        type="text"
                                        name="otp"
                                        maxLength="1"
                                        value={data}
                                        onChange={(e) => handleChange(e.target, index)}
                                        onFocus={(e) => e.target.select()}
                                        ref={(el) => (inputRefs.current[index] = el)}
                                        className="w-12 h-12 text-center border border-yellow-500 rounded-xl bg-yellow-200 text-black text-xl"
                                    />
                                ))}
                            </div>
                        </div>
                        {error && <p className="text-red-500 mb-4">{error}</p>}
                        <button type="submit" className="bg-black hover:bg-gray-900 text-white px-4 py-2 rounded-md w-full font-bold">Verify OTP</button>
                    </form>
                </div>
            </CSSTransition>
        </Layout>
    );
};

export default OTPVerification;