import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

const env = process.env.REACT_APP_ENV;

const script = document.createElement('script');
if (env === 'prod') {
    script.src = 'https://jssdk.payu.in/bolt/bolt.min.js';
} else if (env !== 'prod') {
    script.src = 'https://apitest.payu.in/js-sdks/bolt.min.js';
}
document.head.appendChild(script);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);