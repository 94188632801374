import React, { useState } from 'react';
import Header from '../components/Layout/Header';
import Footer from '../components/Layout/Footer';
import ProgressStepper from '../components/Layout/ProgressStepper';

const DocumentsUpload = () => {
    const [documents, setDocuments] = useState({
        certificateOfIncorporation: null,
        memorandumOfAssociation: null,
        articlesOfAssociation: null,
        companyPAN: null,
        companyGST: null,
        startupIndiaCertificate: null,
    });

    const handleFileChange = (e) => {
        const { id, files } = e.target;
        setDocuments({
            ...documents,
            [id]: files[0],
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Here you would call your API to upload the documents
        console.log('Documents uploaded:', documents);
        // Navigate to Investor FAQ page (use react-router's useNavigate hook or similar)
    };

    return (
        <div>
            <Header />
            <ProgressStepper steps={Array.from({ length: 10 }, (_, i) => i + 1)} currentStep={8} />
            <main className="flex flex-col items-center justify-center min-h-screen bg-gray-100 p-4">
                <h1 className="text-2xl font-bold mb-4">Upload Due Diligence Documents</h1>
                <form onSubmit={handleSubmit} className="bg-white p-6 rounded shadow-md w-full max-w-md">
                    <div className="mb-4">
                        <label htmlFor="certificateOfIncorporation" className="block text-gray-700">Certificate of Incorporation</label>
                        <input
                            type="file"
                            id="certificateOfIncorporation"
                            onChange={handleFileChange}
                            className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md"
                            required
                        />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="memorandumOfAssociation" className="block text-gray-700">Memorandum of Association</label>
                        <input
                            type="file"
                            id="memorandumOfAssociation"
                            onChange={handleFileChange}
                            className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md"
                            required
                        />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="articlesOfAssociation" className="block text-gray-700">Articles of Association</label>
                        <input
                            type="file"
                            id="articlesOfAssociation"
                            onChange={handleFileChange}
                            className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md"
                            required
                        />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="companyPAN" className="block text-gray-700">Company PAN</label>
                        <input
                            type="file"
                            id="companyPAN"
                            onChange={handleFileChange}
                            className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md"
                            required
                        />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="companyGST" className="block text-gray-700">Company GST</label>
                        <input
                            type="file"
                            id="companyGST"
                            onChange={handleFileChange}
                            className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md"
                        />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="startupIndiaCertificate" className="block text-gray-700">Startup India Certificate</label>
                        <input
                            type="file"
                            id="startupIndiaCertificate"
                            onChange={handleFileChange}
                            className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md"
                            required
                        />
                    </div>
                    <button type="submit" className="bg-yellow-500 text-white px-4 py-2 rounded">Next</button>
                </form>
            </main>
            <Footer />
        </div>
    );
};

export default DocumentsUpload;