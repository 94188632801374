import React, { useState } from 'react';
import Header from '../components/Layout/Header';
import Footer from '../components/Layout/Footer';
import ProgressStepper from '../components/Layout/ProgressStepper';

const PitchDeckUpload = () => {
    const [pitchDeck, setPitchDeck] = useState(null);

    const handleFileChange = (e) => {
        setPitchDeck(e.target.files[0]);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Here you would call your API to upload the pitch deck
        console.log('Pitch deck uploaded:', pitchDeck);
        // Navigate to Due Diligence Documents Upload page (use react-router's useNavigate hook or similar)
    };

    return (
        <div>
            <Header />
            <ProgressStepper steps={Array.from({ length: 10 }, (_, i) => i + 1)} currentStep={7} />
            <main className="flex flex-col items-center justify-center min-h-screen bg-gray-100 p-4">
                <h1 className="text-2xl font-bold mb-4">Upload Your Pitch Deck</h1>
                <form onSubmit={handleSubmit} className="bg-white p-6 rounded shadow-md w-full max-w-md">
                    <div className="mb-4">
                        <label htmlFor="pitchDeck" className="block text-gray-700">Pitch Deck</label>
                        <input
                            type="file"
                            id="pitchDeck"
                            onChange={handleFileChange}
                            className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md"
                            required
                        />
                    </div>
                    <button type="submit" className="bg-yellow-500 text-white px-4 py-2 rounded">Next</button>
                </form>
            </main>
            <Footer />
        </div>
    );
};

export default PitchDeckUpload;