import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Layout from '../components/Layout/Layout';
import axiosAuthProtected from '../utils/axiosAuthProtected';
import { CSSTransition } from 'react-transition-group';

const FundingGoal = () => {
    const step = 500000; // Step value of 5 Lacs
    const [funding, setFunding] = useState(1000000); // Default value of 10 Lacs
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleSliderChange = (event) => {
        const value = Number(event.target.value);
        const roundedValue = Math.round(value / step) * step; // Snap to nearest step value
        setFunding(roundedValue);
    };

    const formatINR = (value) => {
        if (value >= 10000000) {
            return `₹${(value / 10000000).toFixed(1)} Crore`;
        } else if (value >= 100000) {
            return `₹${(value / 100000).toFixed(1)} Lakh`;
        } else {
            return `₹${value.toLocaleString('en-IN')}`;
        }
    };

    const formatUSD = (value) => {
        const usdValue = value / 80;
        if (usdValue >= 1000000000) {
            return `US$ ${(usdValue / 1000000000).toFixed(1)}B`;
        } else if (usdValue >= 1000000) {
            return `US$ ${(usdValue / 1000000).toFixed(1)}M`;
        } else if (usdValue >= 1000) {
            return `US$ ${(usdValue / 1000).toFixed(1)}K`;
        } else {
            return `US$ ${usdValue.toFixed(2)}`;
        }
    };

    const handleButtonClick = async () => {
        try {
            const response = await axiosAuthProtected.post('/startup/fund-raise-goal/', {
                fund_raise_goal_amount: funding,
            });
            if (response.data.success) {
                console.log('Fund raise goal set:', response.data);
                navigate('/payment');
            } else {
                setError('Failed to set fund raise goal. Please try again.');
            }
        } catch (error) {
            console.error('Error setting fund raise goal:', error);
            setError('Failed to set fund raise goal. Please try again.');
        }
    };

    return (
        <Layout currentStep={1}>
            <CSSTransition in={true} appear={true} timeout={750} classNames="fade">
                <div className="flex flex-col items-center justify-center w-full px-4 rounded-lg">
                    <div className="bg-yellow-500 p-6 rounded-2xl shadow-md w-full max-w-md mx-auto text-center">
                        <h1 className="text-2xl font-bold mb-4">Set Your Funding Target</h1>
                        <input
                            type="range"
                            min="1000000"
                            max="100000000"
                            step={step}
                            value={funding}
                            onChange={handleSliderChange}
                            className="w-full appearance-none h-2 bg-white rounded-full cursor-pointer"
                            style={{ background: `linear-gradient(to right, black ${((funding - 1000000) / (100000000 - 1000000)) * 100}%, white 0%)` }}
                        />
                        <style jsx>{`
                            input[type="range"]::-webkit-slider-thumb {
                                appearance: none;
                                width: 24px;
                                height: 24px;
                                border-radius: 50%;
                                background: black;
                                cursor: pointer;
                                border: none;
                            }

                            input[type="range"]::-moz-range-thumb {
                                width: 24px;
                                height: 24px;
                                border-radius: 50%;
                                background: black;
                                cursor: pointer;
                                border: none;
                            }

                            input[type="range"]::-ms-thumb {
                                width: 24px;
                                height: 24px;
                                border-radius: 50%;
                                background: black;
                                cursor: pointer;
                                border: none;
                            }
                        `}</style>
                        <div className="mt-4 text-black font-bold">
                            {formatINR(funding)} <br />
                            <span className="text-sm text-gray-600">~{formatUSD(funding)}</span>
                        </div>
                        <button
                            onClick={handleButtonClick}
                            className="mt-4 bg-black text-white px-4 py-2 rounded-lg font-bold"
                        >
                            I want to raise {formatINR(funding)}
                        </button>
                        {error && <p className="text-red-500 mt-4">{error}</p>}
                    </div>
                </div>
            </CSSTransition>
        </Layout>
    );
};

export default FundingGoal;