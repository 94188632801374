import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Layout from '../components/Layout/Layout';
import axiosAuthProtected from '../utils/axiosAuthProtected';
import { CSSTransition } from 'react-transition-group';

const Payment = () => {
    const [payuData, setPayuData] = useState(null);
    const [error, setError] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const fetchPayuData = async () => {
            try {
                const response = await axiosAuthProtected.get('/startup/payu-data/');
                if (response.data.success) {
                    setPayuData(response.data.data.payu_data);
                } else {
                    setError('Failed to fetch payment data. Please try again.');
                }
            } catch (error) {
                console.error('Error fetching payment data:', error);
                setError('Failed to fetch payment data. Please try again.');
            }
        };
        fetchPayuData();
    }, []);

    const handlePayment = () => {
        var handlers = {responseHandler: function (BOLT) {
                if(BOLT.response.txnStatus === "SUCCESS"){
                    navigate('/all-set');
                }
                if (BOLT.response.txnStatus === "FAILED") {
                    setError('Payment failed. Please try again.');
                }
                if(BOLT.response.txnStatus === "CANCEL"){
                    setError('Payment Cancelled. Please make the payment again to proceed.');
                }
            },
            catchException: function (BOLT) {
                setError('Payment failed. Please try again.');
            }};

        const jsonString = payuData.replace(/'/g, '"');
        const jsonObject = JSON.parse(jsonString);

        window.bolt.launch(jsonObject, handlers);
    };

    return (
        <Layout currentStep={2}>
            <CSSTransition in={true} appear={true} timeout={750} classNames="fade">
                <div className="flex flex-col items-center justify-center w-full px-4 rounded-lg">
                    <div className="bg-yellow-500 p-6 rounded-2xl shadow-md w-full max-w-md mx-auto text-center">
                        <h1 className="text-2xl font-bold mb-4">You are just one step away to get listed on the BloomSight fundraise platform.</h1>
                        <p className="text-black mb-4">
                            We charge a nominal fee of ₹25,000 + GST to cover the administrative costs and ensure that your application is
                            processed efficiently.
                        </p>
                        {error && <p className="text-red-500 mb-4">{error}</p>}
                        <button
                            onClick={handlePayment}
                            className="mt-4 bg-black text-white px-4 py-2 rounded-lg font-bold"
                            disabled={!payuData}
                        >
                            {payuData ? `Proceed to Payment` : 'Loading...'}
                        </button>
                    </div>
                </div>
            </CSSTransition>
        </Layout>
    );
};

export default Payment;