import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import { isDesktop } from '../../utils/responsive';
import ProgressStepper from "./ProgressStepper";

const Layout = ({ children, currentStep }) => {
    const location = useLocation();
    const showStepper = !['/', '/verify-phone' , '/verify-otp'].includes(location.pathname);
    const [isDesktopDevice, setIsDesktopDevice] = useState(isDesktop());

    return (
        <div className="layout-container flex flex-col min-h-screen">
            <Header currentStep={currentStep} />
            <div className={`flex-grow flex w-full ${!isDesktop && showStepper ? '' : 'mb-32'}`}>
                {showStepper && (
                    <div className="fixed lg:w-1/4 hidden lg:flex flex-col p-4 h-full">
                        <ProgressStepper currentStep={currentStep} />
                    </div>
                )}
                <main className={`flex-grow ${showStepper ? 'lg:ml-1/4' : 'w-full'} flex items-center justify-center full-height-minus-header-footer w-full overflow-auto `}>
                    {children}
                </main>
            </div>
            <Footer />
        </div>
    );
};

export default Layout;