import React, { useState } from 'react';
import Header from '../components/Layout/Header';
import Footer from '../components/Layout/Footer';
import ProgressStepper from '../components/Layout/ProgressStepper';

const FAQ = () => {
    const [faqs, setFaqs] = useState([{ question: '', answer: '' }]);

    const handleChange = (index, e) => {
        const updatedFaqs = faqs.map((faq, i) =>
            i === index ? { ...faq, [e.target.name]: e.target.value } : faq
        );
        setFaqs(updatedFaqs);
    };

    const handleAddFaq = () => {
        setFaqs([...faqs, { question: '', answer: '' }]);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Here you would call your API to save FAQ details
        console.log('FAQ details submitted:', faqs);
        // Navigate to All Set page (use react-router's useNavigate hook or similar)
    };

    return (
        <div>
            <Header />
            <ProgressStepper steps={Array.from({ length: 10 }, (_, i) => i + 1)} currentStep={9} />
            <main className="flex flex-col items-center justify-center min-h-screen bg-gray-100 p-4">
                <h1 className="text-2xl font-bold mb-4">Add FAQs for Investors</h1>
                <form onSubmit={handleSubmit} className="bg-white p-6 rounded shadow-md w-full max-w-md">
                    {faqs.map((faq, index) => (
                        <div key={index} className="mb-4">
                            <label htmlFor={`question-${index}`} className="block text-gray-700">Question</label>
                            <input
                                type="text"
                                id={`question-${index}`}
                                name="question"
                                value={faq.question}
                                onChange={(e) => handleChange(index, e)}
                                className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md"
                                required
                            />
                            <label htmlFor={`answer-${index}`} className="block text-gray-700">Answer</label>
                            <textarea
                                id={`answer-${index}`}
                                name="answer"
                                value={faq.answer}
                                onChange={(e) => handleChange(index, e)}
                                className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md"
                                required
                            />
                        </div>
                    ))}
                    <button type="button" onClick={handleAddFaq} className="bg-gray-500 text-white px-4 py-2 rounded mb-4">Add Another FAQ</button>
                    <button type="submit" className="bg-yellow-500 text-white px-4 py-2 rounded">Next</button>
                </form>
            </main>
            <Footer />
        </div>
    );
};

export default FAQ;