import React, { useState } from 'react';
import {Link, useLocation} from 'react-router-dom';
import { isDesktop } from '../../utils/responsive';
import ProgressStepper from "./ProgressStepper";

const Header = ({ currentStep }) => {
    const [menuOpen, setMenuOpen] = useState(false);
    const isDesktopDevice = isDesktop();
    const location = useLocation();
    const showStepper = !['/', '/verify-phone' , '/verify-otp'].includes(location.pathname);
    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    return (
        <header className="bg-yellow-500  p-4 flex flex-col fixed top-0 z-50 w-full rounded-b-2xl">
            <div className="flex justify-between items-center w-full">
                <div className="text-2xl font-bold text-black">BloomSight Ventures</div>
                {/*<nav className="hidden md:flex">*/}
                {/*    <Link to="/" className="text-black mr-4">Home</Link>*/}
                {/*    <Link to="/about" className="text-black">About</Link>*/}
                {/*</nav>*/}
                {/*<button onClick={toggleMenu} className="md:hidden text-black">*/}
                {/*    <svg*/}
                {/*        className="w-6 h-6"*/}
                {/*        fill="none"*/}
                {/*        stroke="currentColor"*/}
                {/*        viewBox="0 0 24 24"*/}
                {/*        xmlns="http://www.w3.org/2000/svg"*/}
                {/*    >*/}
                {/*        <path*/}
                {/*            strokeLinecap="round"*/}
                {/*            strokeLinejoin="round"*/}
                {/*            strokeWidth="2"*/}
                {/*            d="M4 6h16M4 12h16m-7 6h7"*/}
                {/*        ></path>*/}
                {/*    </svg>*/}
                {/*</button>*/}
                {/*{menuOpen && (*/}
                {/*    <nav className="md:hidden absolute top-16 left-0 w-full bg-primary flex flex-col items-center">*/}
                {/*        <Link to="/" className="text-black py-2" onClick={toggleMenu}>Home</Link>*/}
                {/*        <Link to="/about" className="text-black py-2" onClick={toggleMenu}>About</Link>*/}
                {/*    </nav>*/}
                {/*)}*/}
            </div>
            {!isDesktopDevice && showStepper && (
                <div className="w-full flex justify-center p-0 m-0">
                    <ProgressStepper currentStep={currentStep} />
                </div>
            )}
        </header>
    );
};

export default Header;