import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Layout from '../components/Layout/Layout';
import { CSSTransition } from 'react-transition-group';
import axiosOpen from "../utils/axioOpen";

const PhoneNumberEntry = () => {
    const [phoneNumber, setPhoneNumber] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handlePhoneNumberChange = (e) => {
        const value = e.target.value;
        if (value.length <= 10 && /^[0-9]*$/.test(value)) {
            setPhoneNumber(value);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axiosOpen.post('startup/register/', { phone_number: phoneNumber });
            console.log('User ID:', response.data.user);
            const userId = response.data.user;
            console.log('User ID:', userId);
            navigate(`/verify-otp?user_id=${userId}`);
        } catch (error) {
            console.error('Error registering phone number:', error);
            setError('Failed to register phone number. Please try again.');
        }
    };

    return (
        <Layout>
            <CSSTransition in={true} appear={true} timeout={750} classNames="fade">
                <div className="form-container center-content w-full">
                    <form onSubmit={handleSubmit} className="bg-yellow-500 p-6 rounded-2xl shadow-md w-full max-w-md mr-2 ml-2">
                        <div className="mb-4">
                            <label htmlFor="phoneNumber" className="block text-black font-bold">Enter Your Number</label>
                            <input
                                type="tel"
                                id="phoneNumber"
                                value={phoneNumber}
                                onChange={handlePhoneNumberChange}
                                className="mt-1 block w-full px-4 py-2 border border-yellow-500 rounded-md bg-yellow-200 text-black"
                                required
                            />
                        </div>
                        {error && <p className="text-red-500 mb-4">{error}</p>}
                        <button type="submit" className="bg-black hover:bg-gray-900 text-white px-4 py-2 rounded-md w-full font-bold">Send OTP on WhatsApp</button>
                    </form>
                </div>
            </CSSTransition>
        </Layout>
    );
};

export default PhoneNumberEntry;