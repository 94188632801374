import React from 'react';

const Footer = () => {
    return (
        <footer className="bg-primary p-4 text-center text-white bg-black w-full fixed bottom-0 z-50 rounded-t-2xl">
            &copy; {new Date().getFullYear()} BloomSight Ventures.<br/>
            <a href={"http://facebook.com/"}><u>Need Help or Want to talk to our Associate?</u></a>
        </footer>
    );
};

export default Footer;